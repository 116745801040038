nav {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding: 0px 2.5%;
    border-bottom: 1px solid #628FBC;
}

.navLeft {
    display: flex;
    align-items: center;
    gap: 25px;
}

.navLeft img {
    width: 60px;
    height: 60px;
}

.navLeft ul {
    display: flex;
    gap: 25px;
}

.navLeft ul li {
    cursor: pointer;
    font-size: 18px;
    list-style-type: none;
    text-decoration: none;
}

.navLeft ul li a {
    list-style-type: none;
    text-decoration: none;
    color: none;
}

.navLeft ul li:hover {
    color: #3D2937;
    transform: scale(1.04);
    font-weight: 500;
}

.navLeft ul .active {
    font-weight: 600;
}

.navLeft ul .active:hover {
    font-weight: 600;
}

.navRight {
    display: flex;
    align-items: center;
    gap: 25px;
}

.navRight .search {
    border: 1px solid #628FBC;
    border-radius: 10px;
    height: 35px;
    width: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 10px;
    gap: 10px;
    cursor: pointer;
}

.navRight .search:focus {
    background-color: lavenderblush;
}

.navRight .search input {
    flex-grow: 1;
    border: none;
    outline: none;
}

.navRight .loginForm {
    display: flex;
    align-items: center;
    gap: 10px;
}

.navRight .loginForm button {
    border: none;
    background: none;
    font-size: 18px;
    cursor: pointer;
    color: #3D2937;
}

.navRight .loginForm button:hover {
    transition: .2s;
    color: black;
    transform: scale(1.05);
    border-bottom: 1px solid #000;
}

.profilDropBtn {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    cursor: pointer;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #33333313;
}

.profilDropBtn svg {
    background: transparent;
    font-size: 18px;
}

.profilDropBtn:hover {
    background: #3333334e;
    color: white;
}

.none {
    display: none;
}

.profilDropMenu {
    position: absolute;
    right: 40px;
    top: 60px;
    width: 100% auto;
    display: flex;
    flex-direction: column;
    z-index: 9;
    background-color: white;
    border-radius: 10px;
    border: 1px solid #728eb8;
    padding: 2px;
}

.profilDropMenu button {
    width: 140px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0px 5px;
    cursor: pointer;
    border-radius: 10px;
    gap: 5px;
    font-size: 15px;
    color: #3D2937;
    border: none;
    background: transparent;
    border: 1px solid transparent;
}

.profilDropMenu button:hover {
    border: 1px solid #728eb8;
    background: #728eb834;
}

.profilDropMenu button svg {
    background: transparent;
}

.profilDropMenu button:nth-child(3) {
    color: #f63832;
}

.profilDropMenu button:nth-child(3):hover {
    background: #e1817e49;
    border: 1px solid #f63832;
}

/* profil modal style */

.profilModal {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #00000022;
}

.profilModal_box {
    width: 420px; 
    height: 600px;
    border-radius: 15px;
    background: #f1f7fe;
    border: 1px solid #beccde;
    padding: 15px;
}
.profilModal_box input {
    padding: 5px;
    border-radius: 10px;
    border: 1px solid #628FBC;
    outline-color: #74a4eb;
}

.profilModal_box_userHeadInfo_info input {
    width: 140%;
}

.profilModal_box_head {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.profilModal_box_head p {
    font-size: 19px;
    font-weight: 600;
}

.profilModal_box_head button {
    border: none;
    background: none;
    cursor: pointer;
    font-size: 18px;
}

.profilModal_box_userHeadInfo {
    display: flex;
    align-items: center;
    gap: 30px;
    padding-bottom: 20px;
    margin: 30px 0;
    border-bottom: 1px solid #beccde;
}

.profilModal_box_userHeadInfo_img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 32px;
    color: #878c8f;
    margin-left: 20px;
    background-color: white;
}

.profilModal_box_userHeadInfo_img img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover
}

.profilModal_box_userHeadInfo_info {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.profilModal_box_userHeadInfo_info h4 {
    font-size: 21px;
}

.profilModal_box_userHeadInfo_info p {
    font-size: 16px;
    color: #878c8f;
}

.profilModal_box_main {
    display: flex;
    flex-direction: column;
}

.profilModal_box_main label {
    text-transform: uppercase;
    font-size: 16px;
    color: #432a30;
    font-weight: 500;
    margin: 5px 0;
}

.profilModal_box_main p {
    font-size: 15px;
    margin-bottom: 10px;
}

.profilModal_box_main_end {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 20px;
}

.profilModal_box_main_end button:nth-child(1) {
    padding: 10px 15px;
    border: 1px solid #91a7c9;
    border-radius: 15px;
    cursor: pointer;
}

.profilModal_box_main_end button:nth-child(1):hover {
    background: #74a4eb;
    color: #3D2937;
}

.profilModal_box_main_end button:nth-child(2) {
    padding: 11px 16px;
    background: #e97470;
    border-radius: 15px;
    border: none;
    cursor: pointer;
    color: #3D2937;
}

.profilModal_box_main_end button:nth-child(2):hover {
    background: #e97470ed;
}


@media screen and (max-width: 768px) {
    nav {
        display: none;
    }

    .profilDropMenu {
        right: 10px;
    }

    .profilModal {
        width: auto;
        height: auto;
        z-index: 99;
    }
    .profilModal_box {
        width: 100%;
        height: 100vh;
        padding: 10px;
        border-radius: 1px;
        border: none;
    }
    .profilModal_box_userHeadInfo_img {
        width: 70px;
        height: 70px;
        padding: 0;
        /* margin: 0; */
    }
    .profilModal_box_userHeadInfo_img img {
        width: auto;
    }
}