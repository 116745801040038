.container {
   display: flex;
   width: 100%;
}

aside {
   border-right: 1px solid black;
   padding: 5px 10px;
   width: 20%;
   height: 92vh;
   z-index: 2;
   transition: .3s;
   overflow-y: auto;
   position: relative;
}

.main_chat_data_chat_main br {
   display: none;
}

.disabled-button {
   background-color: #ccc;
   cursor: not-allowed;
}



/* aside close style */

.close {
   position: absolute;
   transform: translateX(-3333px);
   left: 0;
   transition: .3s;
}

.asideProfilInfo {
   position: sticky;
   width: 100%;
   display: flex;
   align-items: center;
   gap: 10px;
   cursor: pointer;
   padding: 10px 10px;
   z-index: 2;
   border-radius: 15px;
   bottom: 0;
}

.asideProfilInfo:hover {
   background-color: #88b0ee;
}

.asideProfilInfo img {
   width: 35px;
   height: 35px;
   border-radius: 50%;
   border: 1px solid black;
}

.asideProfilInfo p {
   font-weight: 700;
   background: transparent;
}

#openAside {
   width: 40px;
   height: 40px;
   border: none;
   background: none;
   font-size: 18px;
   cursor: pointer;
   border-radius: 5px;
   position: absolute;
   transform: translateX(5px) translateY(5px);
}

#openAside:hover {
   background-color: #88b1ee20;
}

.createList {
   display: flex;
   align-items: center;
   cursor: pointer;
   padding: 5px 5px;
   gap: 5px;
   border-radius: 15px;
}

.createList:hover {
   background-color: #88b0ee;
}

.createList img {
   width: 40px;
   background: transparent;
}

.createList span {
   flex-grow: 1;
   background: transparent;
   color: #392a30;
}

.createList svg {
   color: #392a30;
   background: transparent;
   font-size: 18px;
}

.asideChatData {
   display: flex;
   flex-direction: column;
   padding: 10px 15px;
}

.asideChatData_title {
   font-size: 16px;
   color: #392a30;
}

.asideChatData div {
   display: flex;
   flex-direction: column;
}

.asideChatData div p {
   font-size: 18px;
   color: #392a30;
   padding: 10px;
   cursor: pointer;
   border-radius: 10px;
}

.asideChatData div p:hover {
   background-color: #88b1ee20;
}


.main {
   position: absolute;
   width: 80%;
   height: 85vh;
   display: flex;
   align-items: center;
   flex-direction: column;
   justify-content: space-between;
   margin-left: 20%;
}

.main_chat {
   width: 80%;
   height: 80vh;
   margin: 1% 5%;
   display: flex;
   flex-direction: column;
   gap: 25px;
   transition: .5s;
   overflow: auto;
}

::-webkit-scrollbar {
   width: 5px;
 }
 
 /* Track */
 ::-webkit-scrollbar-track {
   box-shadow: inset 0 0 5px rgba(128, 128, 128, 0.577); 
   border-radius: 10px;
 }
  
 /* Handle */
 ::-webkit-scrollbar-thumb {
   background: #33333323; 
   border-radius: 10px;
 }
 
 /* Handle on hover */
 ::-webkit-scrollbar-thumb:hover {
   background: #3333332c; 
 }


.main_chat_data {
   display: flex;
   align-items: flex-start;
   gap: 15px;
}

.main_chat_data_userImg {
   width: 30px;
   height: 30px;
   border: 1px solid #333;
   border-radius: 50%;
   display: flex;
   align-items: center;
   justify-content: center;
}

.main_chat_data_userImg img {
   width: 30px;
   height: 30px;
   border-radius: 50%;
   object-fit: contain;
}

.main_chat_data_chat {
   display: flex;
   flex-direction: column;
   gap: 10px;
}

.main_chat_data_chat span {
   font-size: 18px;
   font-weight: 600;
   color: #000;
}

.main_chat_data_chat p {
   font-size: 18px;
   color: #392a30;
   letter-spacing: 0.5px;
   line-height: 25px;
}

.sendForm {
   width: 60%;
   height: auto;
   border-radius: 25px;
   border: 1px solid #a3a3a3;
   display: flex;
   padding: 10px;
   align-items: flex-end;
   transition: .5s;
   position: absolute;
   bottom: -20px;
}

.sendForm textarea {
   flex-grow: 1;
   border: none;
   outline: none;
   font-size: 18px;
   overflow: hidden; /* Prevent scrollbars */
   resize: none; /* Prevent manual resizing */
   min-height: 40px; /* Minimum height */
   max-height: 200px; /* Maximum height to avoid infinite growth */
   height: auto; /* Initial height set to auto */
}



.sendForm button {
   width: 30px;
   height: 30px;
   background: #ebdef2;
   cursor: pointer;
   display: flex;
   align-items: center;
   justify-content: center;
   font-size: 14px;
   border: none;
   border-radius: 10px;
}

.sendForm button svg {
   background: transparent;
   color: #9197a1;
}

.sendForm input::placeholder {
   color: #392a30;
   letter-spacing: .3px;
}


.asideTopMenu {
   width: 100%;
   display: flex;
   justify-content: space-between;
}

.asideTopMenu button {
   width: 40px;
   height: 40px;
   border: none;
   background: none;
   font-size: 18px;
   cursor: pointer;
   border-radius: 5px;
}

.asideTopMenu button:hover {
   background-color: #88b1ee20;
}

.asideTopMenu button svg {
   background: transparent;
}

.asideTopMenu button:active {
   transform: scale(0.96);
}

.main_chat_mobileHead {
   display: none;
}


.mobileLogin {
   display: none;
}

.mobileProfil {
   display: none;
}

.profilDropBtn_mobile {
   display: none;
}

.laungeSelect {
   display: flex;
   flex-direction: column;
   border-top: 1px solid #33333345;
   border-bottom: 1px solid #33333345;
   margin-top: 10px;
   position: relative;
}

.laungeSelect_default {
   width: 100%;
   display: flex;
   align-items: center;
   justify-content: space-between;
   padding: 10px; 
}


.laungeSelect_default span {
   display: flex;
   align-items: center;
   gap: 10px;
}

.laungeSelect_default span:nth-child(1) {
   padding: 10px;
   font-size: 18px;
}

.laungeSelect_default span:nth-child(2) {
   padding: 10px;
   border-radius: 10px;
}

.laungeSelect_default span:nth-child(2):hover {
   cursor: pointer;
   background-color: rgba(0, 0, 0, 0.127);
}

.laungeSelect_default span svg {
   font-weight: 100;
   background: transparent;
}

.lengs {
   display: flex;
   flex-direction: column;
   position: absolute;
   right: 0px;
   top: 60px;  
   border-radius: 10px;
   box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.203);
   padding: 5px 0;
}

.lengs span {
   padding:10px;
   border-radius: 10px;
   margin: 0px 10px;
}

.lengs span:hover {
   cursor: pointer;
   background-color: rgba(0, 0, 0, 0.127);
}


@media screen and (max-width: 768px) {
   #openAside {
      position: fixed;
      font-size: 22px;
   }

   .main {
      width: 100%;
      height: 100vh;
      position: static;
   }

   .sendForm {
      position: fixed;
      width: 90%;
      bottom: 2%;
   }

   .main_chat {
      width: 100%;
      position: absolute;
      top: 60px;
      left: 0;
      overflow-y: auto;
      bottom: 60px;
      /* Adjust this if you have a fixed footer */
      padding-bottom: 60px;
      /* Adjust this if you have a fixed footer */
   }


   .main_chat_mobileHead {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: fixed;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      top: 2.5%;
   }

   .main_chat_mobileHead img {
      width: 40px;
      height: 40px;
   }

   .main_chat_mobileHead span {
      font-size: 18px;
   }

   aside {
      border-right: 1px solid black;
      padding: 5px 10px;
      width: 80%;
      height: 100%;
      z-index: 9999;
      transition: .3s;
      overflow-y: auto;
      position: absolute;
   }

   .close {
      position: absolute;
      transform: translateX(-3333px);
      left: auto;
      transition: .3s;
   }

   .sendForm input {
      font-size: 19px;
   }

   .mobileLogin {
      display: flex;
      flex-direction: column;
      gap: 10px;
      position: absolute;
      top: 5px;
      right: 10px;
      color: #392a30;
      align-items: flex-end;
   }

   .mobileLogin a {
      width: 80px;
      border: 1px solid;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #e4c5e6;
      border: none;
      border-radius: 5px;
      font-size: 16px;
      text-decoration: none;
      list-style-type: none;
      color: black;
      padding: 2px 1px;
   }

   .mobileProfil {
      width: 40px;
      height: 40px;
      display: flex;
      position: absolute;
      right: 10px;
      top: 10px;
      border-radius: 50%;
   }

   .profilDropBtn_mobile {
      display: flex;
      position: absolute;
      top: 5px;
      right: 5px;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      align-items: center;
      justify-content: center;
      background: #33333313;
      border: none;
   }

   .profilDropBtn_mobile svg {
      background: transparent;
      font-size: 17px;
   }
}

input[type="file"] {
   display: none;
}

.custom-file-upload {
   display: inline-block;
   cursor: pointer;
   padding: 5px;
   display: flex;
   align-items: center;
   justify-content: center;
   font-size: 19px;
}

.sendForm .redim {
   background: red;
}

.sendForm .redim svg {
   color: white;
}