.FaqText {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    padding-top: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

h2 {
    text-align: center;
    font-size: 28px;
    color: #222;
    margin-bottom: -4px;
    font-weight: 700;
}

.FaqTextP {
    text-align: center;
    font-size: 18px;
    color: #555;
    margin-bottom: 20px;
    margin-top: 20px;
}

.FaqParagraphs {
    height: 80vh;   
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    align-items: center;
    justify-content: center;
    gap: 25px;
    word-wrap: break-word;
    overflow: auto;
    padding: 10px 0;
}

.FaqParagraph {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    word-wrap: break-word;
    background-color: #ffffff;
    border-radius: 12px;
    padding: 25px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.FaqParagraph h3 {
    font-size: 20px;
    line-height: 1.4;
    font-weight: 600;
    margin-bottom: 15px;
    color: #0071e3;
    background-color: transparent;
}

.FaqParagraph p {
    font-size: 16px;
    line-height: 1.6;
    color: #666;
    margin-bottom: 0;
    background-color: transparent;
}

.FaqParagraph:hover {
    transform: translateY(-8px);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
}