@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');


* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  list-style-type: none;
  font-family: "Roboto", sans-serif;
  background-color: #F0F7FF;
}

body {
  height: 100vh;
  overflow-y: hidden;
}


@media screen and (max-width: 768px)  {
  body {
    overflow: hidden;
  }
}