.homeGlav {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: #fbfbfd;
    overflow-y: scroll;
}

.homeGlav_nav {
    width: 100%;
    padding: 5px 20%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    position: fixed;
}


.homeGlav_nav h2 {
    color: #0071e3;
    background-color: white;
}

.homeGlav_nav ul {
    display: flex;
    background-color: transparent;
    gap: 20px;
}

.homeGlav_nav ul li {
    background-color: transparent;
    cursor: pointer;
    text-decoration: none;
    list-style-type: none;
}

.homeGlav_nav ul a {
    text-decoration: none;
    list-style-type: none;
    color: black;
    background-color: transparent;
}

.homeGlav_nav ul li:hover {
    color: #0071e3;
}

.homeGlav_box:nth-child(2) {
    margin-top: 40px;
}

.homeGlav_box:nth-child(even) {
    background-color: rgba(0, 110, 255, 0.26);
}

.homeGlav_box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5% 0;
    gap: 20px;
}

.homeGlav_box h1 {
    font-size: 56px;
    background-color: transparent;
}

.homeGlav_box p {
    max-width: 80%;
    background-color: transparent;
    font-size: 22px;
    text-align: center;
}


.homeGlav_box button {
    padding: 10px 25px;
    border-radius: 25px;
    color: white;
    background-color: #0071e3;
    border: none;
    font-size: 18px;
    cursor: pointer;
}

.homeGlav_box button:hover {
    background-color: #0071e3cc;
}

.homeGlav_box_s {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    background-color: transparent;
    margin-top: 40px;
}

.homeGlav_box_s_box {
    width: 360px;
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    background-color: white;
    gap: 20px;
    border-radius: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
}

.homeGlav_box_s_box:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.homeGlav_box_s_box h3 {
    background-color: transparent;
    font-size: 28px;
    text-align: center;
}

.homeGlav_box_s_box p {
    font-size: 22px;
    background-color: transparent;
}

.homeGlav_form {
    width: 100%;
    height: 75vh;
    background-color: rgba(0, 110, 255, 0.26);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding: 7.5% 0;
}

.homeGlav_form h1 {
    font-size: 56px;
    background-color: transparent;
}

.homeGlav_form p {
    max-width: 80%;
    background-color: transparent;
    font-size: 22px;
    text-align: center;
}

.homeGlav_form form {
    min-width: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    border-radius: 20px;
    padding: 30px;
    background-color: white;
}

.homeGlav_form form input {
    width: 100%;
    padding: 12px;
    border-radius: 10px;
    border: 1.4px solid #333;
    font-size: 18px;
    background-color: white;
}

.homeGlav_form form textarea {
    width: 100%;
    padding: 12px;
    height: 100px;
    max-height: 100px;
    border-radius: 10px;
    font-size: 18px;
    background-color: white;
}

.homeGlav_form form button {
    padding: 10px 25px;
    border-radius: 25px;
    color: white;
    background-color: #0071e3;
    border: none;
    font-size: 18px;
    cursor: pointer;
}

.homeGlav_form form button:hover {
    background-color: #0071e3cc;
}

.homeGlav_footer {
    width: 100%;
    padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    background-color: rgba(0, 110, 255, 0.26);
}

.homeGlav_footer p {
    background-color: transparent;
    color: #000;
}

.lang {
    display: flex;
    gap: 10px;
    background-color: transparent;
    cursor: pointer;
    margin-left: 80px;
}

.lang span {
    background-color: transparent;
    font-size: 18px;
}

@media screen and (max-width: 900px) {
    .homeGlav_nav {
        width: 100%;
        padding: 0 10px;
        align-items: center;
    }
    .homeGlav_nav ul {
        gap: 10px;
        align-items: center;
        justify-content: center;
    }
    .homeGlav_nav ul li {
        font-size: 18px;
    }
    .lang {
        margin-left: 30px;
    }
    .homeGlav_box {
        gap: 20px;
        padding: 40px 0;
    }
    .homeGlav_box h1 {
        text-align: center;
        font-size: 36px;
    }
    .homeGlav_box_s {
        width: 100%;
        flex-wrap: wrap;
    }

    .homeGlav_form form {
        width: 90%;
        min-width: 90%;
    }
}