.about {
    width: 100%;
    min-height: 100vh; /* Ensures the section covers at least 100vh, but can expand */
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #19191919;
    gap: 40px;
    padding: 5% 0; /* Added padding to provide space on top and bottom */
}

.about_title {
    font-size: 36px;
    background-color: transparent;
}

.about_box {
    width: 80%;
    display: flex;
    padding: 25px;
    border-radius: 20px;
    gap: 20px;
}


.about_boximg {
    height: 100%;
    width: 100%;
}

.about_boximg img {
    width: 100%;
    border-radius: 10px;
    object-fit: cover;
}

.aboutBox_texts {
    display: flex;
    flex-direction: column;
    gap: 20px;
}


.aboutBox_texts p {
    color: #555;
    font-size: 19px;
    line-height: 30px;
}

@media screen and (max-width: 900px) {
    .about {
        padding: 5% 0;
        overflow-y: auto;
    }
    .about_box {
        width: 95%;
        height: auto;
        flex-direction: column;
        max-height: none; /* Allows the box to expand naturally */
        overflow-y: visible; /* Removes the overflow on smaller screens */
    }
    .aboutBox_texts {
        height: 100vw;
        overflow-y: scroll;
        padding-bottom: 50px;
    }
}
