

.loginPage {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loginPage_box {
    min-height: 500px;
    width: 450px;
    border: 1px solid #303843;
    padding: 40px;
    border-radius: 15px;
}

.loginPage_box_head {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.loginPage_box_head h4 {
    font-size: 22px;
    color: #303843;
}

.loginPage_box_head button {
    border: none;
    background: none;
    font-size: 18px;
    padding: 0;
    margin: 0;
    color: #303843;
}

.loginPage_box_head button:hover {
    border-bottom: 1px solid #303843;
    cursor: pointer;
}

.loginTitle {
    width: 90%;
    margin: 5px 0;
}

.loginPage_box form {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-top: 60px;
}

.loginPage_box form button {
    margin: 10px 0;
    height: 40px;
    border-radius: 10px;
    cursor: pointer;
    background-color: #5097ed;
    color: white;
    border: none;
    font-size: 18px;
}

.loginPage_box form button:hover {
    background-color: #308dff;
}

.loginPage_box form input {
    margin-bottom: 10px;
    height: 40px;
    border-radius: 10px;
    padding: 0px 5px;
    border: 1px solid #303843;
}

.loginFooter {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
}

.loginFooter button {
    border: none;
    background-color: transparent;
    border-bottom: 1px solid black;
    cursor: pointer;
    font-size: 16px;
    font-weight: 300;
    color: #303843;
}

.newChatBtn {
    width: 120px;
    height: 35px;
    border-radius: 5px;
    color: white;
    background: #308dff;
    border: none;
    cursor: pointer;
    box-shadow: 1px 1px 5px 1px #308dff5c;
    position: absolute; 
    left: 0; 
    right: 0; 
    top: 40%;
    margin-left: 30%; 
    margin-right: auto; 
}

.newChatBtnCon {
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.138);
    position: absolute;
    left: 0;
}

@media screen and (max-width: 768px) {
    .loginPage_box {
        border: none;
        height: 100%;
    }

    .newChatBtn {
        top: 40%;
        margin-left: auto; 
    }
}

